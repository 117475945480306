export const QUERY_KEYS = {
  ACCOUNT: {
    USERPING: 'account_getUserPing',
    CURRENT_USER: 'account_getCurrentUser',
  },
  // AUTHENTICATE: {
  //   CHECK_THIRDPARTY_REGISTER: 'authenticate_checkThirdPartyRegistered',
  // },
  AFFILIATE: {
    DETAILED_DATA: 'affiliate_getDetailedData',
    COMMISSION_HISTORY: 'affiliate_getCommissionHistory',
    AFF_TEAM_DETAILS: 'affiliate_getAffTeamDetails',
    AFF_LEVELBETTING_DETAILS: 'affiliate_getAffLevelBettingDetails',
    COMMISSION_RATE: 'affiliate_getCommissionRate',
  },
  BANNER: {
    BANNER_LIST: 'banner_getBannerList',
    POPUP_BANNER: 'banner_getPopupBanner',
    BANNER_LIST_VERSION: 'banner_getBannerListVersion',
    POPUP_BANNER_LIST_VERSION: 'banner_getPopupBannerListVersion',
    EVENT_URL: 'banner_getEventUrl',
    SHORTCUT_LIST: 'banner_getShortcutList',
    SHORTCUT_LIST_VERSION: 'banner_shortcutListVersion',
  },
  SMS: {
    CHECK_ACTIVE_SMS_PLATFORM: 'sms_checkActiveSMSPlatform',
  },
  PUBLIC: {
    CHECK_MAINTENANCE: 'public_getCheckMaintenance',
    THIRD_PARTY_SCRIPT_INFO: 'public_getThirdPartyScriptInfo',
    BRAND_SUPPORT_URL: 'public_getBrandSupportUrl',
    BASE_SETTING: 'public_getBaseSetting',
    EXTERNAL_LINKS: 'public_getExternalLinks',
  },
  BONUS: {
    AVAILABLE_BONUS: 'bonus_getAvailableBonus',
    VIP_DATA: 'bonus_getVipData',
    DAILY_BONUS: 'bonus_getdailyBonusData',
    AVAILABEL_FREE_SPIN: 'bonus_getAvailableFreeSpin',
    CLAIMABLE_REWARD: 'bonus_getClaimableReward',
    VIP_LEVEL_BONUS_LIST: 'bonus_getVipLevelBonusList',
  },
  GAME: {
    MAIN_PAGE_CATEGORY_GAME: 'game_getMainPageCategoryGame',
    SUBGAME_LIST: 'game_getSubGameList',
    GAME_CACHE_VERSION: 'game_getGameCacheVersion',
    FAV_GAMEID_LIST: 'game_getFavGameIdList',
    GAME_CATEGORY: 'game_getGameCategory',
    WIN_LIST: 'game_getWinList',
  },
  REFERRALSPIN: {
    PAGE_DETAILS: 'referralSpin_getPageDetails',
    SPIN_BONUS: 'referralSpin_spinReferralBonus',
    CLAIM_BONUS: 'referralSpin_claimReferralBonus',
    LUCKY_USER_LIST: 'referralSpin_luckyUserList',
    REGISTER_RECORD_LIST: 'referralSpin_registerRecordList',
  },
  PROMOTION: {
    PROMOTION_LIST: 'promotion_getPromotionList',
    PROMOTION_DETAIL: 'promotion_getPromotionDetail',
  },
  TRANSACTION: {
    // TRANSACTION_RECORD: 'transaction_getTransactions',
  },
  WALLET: {
    DEPOSIT_CHANNELS: 'wallet_getDepositChannels',
    BINDING_LIST: 'wallet_getBindingList',
    WITHDRAW_INFO: 'wallet_getWithdrawalInfo',
    BALANCE: 'wallet_getBalance',
    VAULT_LOG: 'wallet_getVaultLog',
    BINDING_GET: 'wallet_getBindingGet',
    BINDING_FILTER: 'wallet_getBindingFilter',
    DEPOSIT_SETTING: 'wallet_getDepositSetting',
  },
  // ... other services
};
