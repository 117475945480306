import { ACTIONS } from '../constants/actions';

/**
 * Set user details action
 * @param {Function} dispatch Action dispatcher from store
 * @param {Game} payload Action payload
 */

export const setProvidersList = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.GAME.PROVIDERS_LIST,
  });

export const setRecentGameList = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.GAME.RECENT_GAME_List,
  });
