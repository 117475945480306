import { ACTIONS } from '../constants/actions';

const initialState = {
  providers: [],
  recentGameList: [],
};

export default function gameReducer(game = initialState, { type, payload }) {
  switch (type) {
    case ACTIONS.GAME.PROVIDERS_LIST:
      return { ...game, providers: payload };
    case ACTIONS.GAME.RECENT_GAME_List:
      return { ...game, recentGameList: payload };
    default:
      return game;
  }
}
