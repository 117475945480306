import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch } from '../../store/StateProvider';
import { switchTheme, formatResult } from 'common/helpers/helper';
import {
  getAccessToken,
  getRefreshToken,
  isUserLoggedIn,
} from 'common/helpers/localStorage';
import { setUserDetails, setUserInfo } from 'common/reducer-actions/userActions';
import { enqueueSnackbarMsg } from 'common/helpers/helper';
import { useIntl } from 'react-intl';
import AccountApi from './api/accountApi';
import { QUERY_KEYS } from 'common/constants/query-key';

/******************
 * Handle useQuery/useMutation
 ******************/
export function useUserPing() {
  // const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: [QUERY_KEYS.ACCOUNT.USERPING],
    enabled: false,
    queryFn: AccountApi.userPing,
  });

  return formatResult(query, true);
}

export function useUserSessionActive(userId) {
  const mutation = useMutation({
    mutationFn: () => AccountApi.getUserSessionActive(userId),
  });

  return formatResult(mutation);
}

export function useGetCurrentUser(refreshToken) {
  const dispatch = useDispatch();
  const isLoggedIn = isUserLoggedIn();
  const query = useQuery({
    queryKey: [QUERY_KEYS.ACCOUNT.CURRENT_USER, { refreshToken }],
    queryFn: async () => {
      const reqData = {
        Token: refreshToken,
      };
      const res = await AccountApi.getCurrentUser(reqData);
      if (res) {
        switchTheme(res?.theme);
        const accessToken = getAccessToken();
        const refreshToken = getRefreshToken();
        setUserDetails(dispatch, {
          ...res,
          jwtToken: accessToken,
          refreshToken: refreshToken,
        });
        setUserInfo(dispatch, res);
      }
      return res;
    },
    enabled: isLoggedIn && typeof refreshToken !== 'undefined',
    meta: { preventGlobalError: true },
  });

  return formatResult(query, true);
}

export function useResetPassword() {
  const mutation = useMutation({
    mutationFn: AccountApi.resetPassword,
    onSuccess: (res) => {
      enqueueSnackbarMsg(res.message);
    },
  });

  return formatResult(mutation);
}

export function useUpdateAccount() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: AccountApi.updateAccount,
    onSuccess: (data) => {
      setUserInfo(dispatch, data);
      enqueueSnackbarMsg(intl.formatMessage({ id: 'profile.update_success' }));
    },
  });

  return formatResult(mutation);
}

export function useVerifyAccount() {
  const mutation = useMutation({
    mutationFn: AccountApi.verifyAccount,
    onSuccess: (res) => {
      enqueueSnackbarMsg(res.message);
    },
  });

  return formatResult(mutation);
}

export function useRequestVerifyAccount() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: AccountApi.requestVerifyAccount,
    onSuccess: (res) => {
      const refreshToken = getRefreshToken();
      queryClient.invalidateQueries([QUERY_KEYS.ACCOUNT.CURRENT_USER, { refreshToken }]);
      enqueueSnackbarMsg(
        intl.formatMessage({ id: 'profile.email_text' }),
        'success',
        intl.formatMessage({ id: 'profile.email_verify' }),
      );
      return res;
    },
    onError: (error) => {
      if (error && error.indexOf('been verified') > -1) {
        setUserDetails(dispatch, { isVerified: true });
        enqueueSnackbarMsg(error, 'error');
      }
    },
  });

  return formatResult(mutation);
}

export function useValidateResetCode() {
  const mutation = useMutation({
    mutationFn: AccountApi.validateResetCode,
    onSuccess: (res) => {
      if (!res.token) {
        enqueueSnackbarMsg(res.message, 'error');
      } else {
        enqueueSnackbarMsg(res.message, 'warning');
      }
    },
  });

  return formatResult(mutation);
}

export function useVerifyPassword() {
  const mutation = useMutation({
    mutationFn: AccountApi.verifyPassword,
    onSuccess: (res) => {
      if (res.data) {
        return res;
      } else {
        enqueueSnackbarMsg(res.message, 'error');
      }
    },
  });

  return formatResult(mutation);
}

export function useRemoveMemberPhoneorEmail() {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: AccountApi.removeMemberPhoneorEmail,
    onSuccess: (res) => {
      enqueueSnackbarMsg(res.message);
      const refreshToken = getRefreshToken();
      queryClient.invalidateQueries([QUERY_KEYS.ACCOUNT.CURRENT_USER, { refreshToken }]);
      return res;
    },
  });


  return formatResult(mutation);
}

export function useRequestEmailOTP() {
  const mutation = useMutation({
    mutationFn: AccountApi.requestOTP,
    onSuccess: (res) => {
      enqueueSnackbarMsg(res.message);
      // const refreshToken = getRefreshToken();
      // queryClient.invalidateQueries([QUERY_KEYS.ACCOUNT.CURRENT_USER, { refreshToken }]);
      return res;
    },
  });

  return formatResult(mutation);
}
