import { useQueryClient, useMutation, useQuery } from '@tanstack/react-query';
import { formatResult } from 'common/helpers/helper';
import { useDispatch } from '../../store/StateProvider';
import ReferralSpinApi from './api/referralSpinApi';
import { setLocalStorage } from 'common/helpers/localStorage';
import { QUERY_KEYS } from 'common/constants/query-key';
import { STORAGE } from 'common/constants/keys';
import { setReferralSpinDetails } from 'common/reducer-actions/referralActions';
import { enqueueSnackbarMsg } from 'common/helpers/helper';
import { useIntl } from 'react-intl';

export const useGetReferralSpinDetails = (enabled = true) => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: (params) => ReferralSpinApi.getReferralSpinDetails(),
    onSuccess: (res) => {
      if (res) {
        setReferralSpinDetails(dispatch, res);
        return res;
      }
    },
    meta: { preventGlobalError: true },
  });
  return formatResult(mutation);
};

export const useSpinReferralBonus = () => {
  const mutation = useMutation({
    queryKey: [QUERY_KEYS.REFERRALSPIN.SPIN_BONUS],
    mutationFn: ReferralSpinApi.spinReferralBonus,
  });
  return formatResult(mutation);
};

export const useClaimReferralBonus = () => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const mutation = useMutation({
    mutationFn: ReferralSpinApi.claimReferralBonus,
    onSuccess: (data) => {
      if (data) {
        enqueueSnackbarMsg(intl.formatMessage({ id: 'referral_promotion.claimText' }));
        queryClient.invalidateQueries([QUERY_KEYS.REFERRALSPIN.PAGE_DETAILS]);
      } else {
        enqueueSnackbarMsg(data.errorMessage, 'error');
      }
    },
  });
  return formatResult(mutation);
};

export const useGetLuckyUserList = (enabled = true) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.REFERRALSPIN.LUCKY_USER_LIST],
    queryFn: ReferralSpinApi.getLuckyUserList,
    enabled: enabled,
  });
  return formatResult(query, true);
};

export const useGetRegisterRecordList = (enabled = true) => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.REFERRALSPIN.REGISTER_RECORD_LIST],
    queryFn: ReferralSpinApi.getRegisterRecordList,
    enabled: enabled,
    meta: { preventGlobalError: true },
  });
  return formatResult(query, true);
};
