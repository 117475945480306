import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStore } from 'store/StateProvider';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ROUTES } from 'common/constants/routing';
import { MobileImages } from 'common/constants/image-resource';
import NumFormat from 'component/shared/numformat/NumFormat';
import styles from './MemberBalance.module.css';
import { useGetBalance } from '../../../../../../services/query/walletQuery';
import { isUserLoggedIn } from '../../../../../../common/helpers/localStorage';

const MemberBalance = ({ handleCloseGame }) => {
  //Utils
  const [store] = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = isUserLoggedIn();
  //Axios hook
  const [, fetchBalance] = useGetBalance();
  //Store
  const memberBalance = store.user?.memberBalance;

  const handleToDeposit = () => {
    if (location.pathname !== ROUTES.WALLET.DEPOSIT) {
      navigate(ROUTES.WALLET.DEPOSIT);
    }
  };

  useEffect(() => {
    if (isLoggedIn) fetchBalance();
  }, [isLoggedIn, fetchBalance]);

  return (
    memberBalance !== null && (
      <>
        {handleCloseGame ? (
          <div className={styles.memberWallet}>
            <LazyLoadImage
              src={MobileImages.coin}
              alt=""
              width={20}
              height={21}
              className={styles.coin}
            />
            {/* <NumFormat className={styles.memberBalance} value={memberBalance} /> */}
            <span className={styles.memberBalance}>In Play...</span>
            <span className={styles.walletBtn}>
              <LazyLoadImage
                onClick={(e) => {
                  handleCloseGame(e);
                  navigate(ROUTES.WALLET.DEPOSIT);
                }}
                src={MobileImages.wallet}
                alt=""
                width={20}
                height={10}
                className={styles.wallet}
              />
            </span>
          </div>
        ) : (
          <div onClick={handleToDeposit}>
            <div className={styles.memberWallet}>
              <LazyLoadImage
                src={MobileImages.coin}
                alt=""
                width={20}
                height={21}
                className={styles.coin}
              />
              <NumFormat className={styles.memberBalance} value={memberBalance} />
              <span className={styles.walletBtn}>
                <LazyLoadImage
                  src={MobileImages.wallet}
                  alt=""
                  width={20}
                  height={10}
                  className={styles.wallet}
                />
              </span>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default MemberBalance;
