import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { SnackbarProvider } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import Wrapper from 'component/shared/provider/IntlWrapper';
//store
import { StateProvider } from 'store/StateProvider';
import appReducer from 'store/appReducer';

//routes
import AppRoutes from 'component/mobile/app/routes/AppRoutes';
// import DesktopRoutes from 'component/desktop/app/routes/DesktopRoutes';

//service
import { InjectAxiosInterceptors } from 'services/interceptors/InjectAxiosInterceptors';

//components
import UserProvider from 'component/shared/provider/UserProvider';
import IpAddressProvider from 'component/shared/provider/IpAddressProvider';
import GameProvider from 'component/shared/provider/GameProvider';

//helper
import { loadTheme } from 'common/helpers/helper';

import StructuredData from 'component/shared/StructuredData/StructuredData';
import LoadingProvider from './shared/provider/LoadingProvider';
import CustomSnackBar from './shared/customSnackBar/CustomSnackBar';
import CustomThemeProvider from './shared/provider/CustomThemeProvider';
import MemberBalanceProvider from './shared/provider/MemberBalanceProvider';
import HistoryProvider from './shared/provider/HistoryProvider';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { handleError } from 'common/helpers/helper';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  //preventGlobalError is for api auto calling & it have [Authorize] in Controller, we need to add the meta to bypass handleError
  queryCache: new QueryCache({
    onError: (error, query) => {
      const meta = query.meta ?? {};
      if (!('preventGlobalError' in meta && meta.preventGlobalError)) {
        handleError(error);
        return;
      }
    },
  }),
  mutationCache: new MutationCache({
    onError: (error, query, __, mutation) => {
      const meta = mutation?.meta ?? {};
      if (!('preventGlobalError' in meta && meta.preventGlobalError)) {
        handleError(error);
        return;
      }
    },
  }),
});

if (window.APP_ENV !== 'STAGING') {
  console.log = function () {};
}

const DefaultLayout = () => {
  // const isMobile = useMediaQuery({ maxWidth: 1024 });
  // loadTheme(isMobile);
  // return isMobile ? <AppRoutes /> : <DesktopRoutes />;
  loadTheme(true);
  return <AppRoutes />;
};

const snackBarConfig = {
  variant: 'error',
  autoHideDuration: 3000,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
  Components: {
    success: CustomSnackBar,
    error: CustomSnackBar,
    warning: CustomSnackBar,
    info: CustomSnackBar,
  },
};

const App = () => {
  return (
    <BrowserRouter>
      <StateProvider reducer={appReducer} initialState={{}}>
        <QueryClientProvider client={queryClient}>
          <LoadingProvider>
            <InjectAxiosInterceptors />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <SnackbarProvider {...snackBarConfig}>
                {/* <MemberBalanceProvider> */}
                <UserProvider>
                  <IpAddressProvider>
                    <CustomThemeProvider>
                      <Wrapper>
                        <GameProvider>
                          <HistoryProvider>
                            <DefaultLayout />
                          </HistoryProvider>
                          <StructuredData />
                        </GameProvider>
                      </Wrapper>
                    </CustomThemeProvider>
                  </IpAddressProvider>
                </UserProvider>
                {/* </MemberBalanceProvider> */}
              </SnackbarProvider>
            </LocalizationProvider>
          </LoadingProvider>
        </QueryClientProvider>
      </StateProvider>
    </BrowserRouter>
  );
};

export default App;
