import React, { useEffect, useState } from 'react';

//common
import { isUserLoggedIn, getRefreshToken, getAccessToken } from 'common/helpers/localStorage';

//service
import { useGetCurrentUser, useUserSessionActive, useUserPing } from 'services/query/accountQuery';
import { useUpdateMetaEvt } from 'services/query/publicQuery';
import {
  useGetBannerListVersion,
  useGetPopupBannerListVersion,
  useGetShortcutListVersion,
} from 'services/query/bannerQuery';
import { useGetGameCacheVersion } from 'services/query/gameQuery';
import { STORAGE } from 'common/constants/keys';

//component
import useInterval from 'services/hook/useInterval';
import { useStore } from 'store/StateProvider';
import { useSignalR } from 'services/hook/useSignalR';
import { postMetaPurchaseEvent } from 'common/helpers/metaPixel';
import { recordAjustDeposit } from 'common/helpers/adjust';
import { useGetDeposit } from 'services/query/walletQuery';
import { firstDepositArrivalEvent } from 'common/helpers/appsFlyer';
import { ssGetUID, ssSetUID } from 'common/helpers/sessionStorage';

export default function UserProvider({ children }) {
  //Utils
  const isLoggedIn = isUserLoggedIn();
  const [store] = useStore();
  const accessToken = getAccessToken(STORAGE.AccessToken);
  //Store
  const users = store.user?.users;
  const refreshToken = users?.refreshToken || getRefreshToken();
  // const [params, setParams] = useState(null);
  //Axios hook
  const { data: uData } = useGetCurrentUser(refreshToken);
  const [{ data, loading }, refetch] = useUserSessionActive(users?.id);
  const [, ping] = useUserPing();
  const [, updateMetaEvt] = useUpdateMetaEvt();
  const [{ data: dData }, getDeposit] = useGetDeposit();
  const { startConnection, stopConnection } = useSignalR();
  const [] = useGetGameCacheVersion();
  const [] = useGetBannerListVersion();
  const [] = useGetPopupBannerListVersion();
  const [] = useGetShortcutListVersion();
  const uid = ssGetUID();
  // Get user data callback
  useEffect(() => {
    if (uData) {
      ssSetUID(uData.id);
      const { firstDepositId, firstDepositAmt, isMetaEventSent } = uData;
      if (firstDepositId && firstDepositAmt > 0 && isMetaEventSent === 0) {
        getDeposit({ DepositId: firstDepositId });
      }
    }
  }, [uData, getDeposit]);

  // Get Deposit callback
  useEffect(() => {
    if (dData) {
      const { deposit } = dData;
      const successPost = postMetaPurchaseEvent(deposit.id, deposit.amount, deposit.updated);
      firstDepositArrivalEvent(deposit.amount);
      recordAjustDeposit(deposit.id, deposit.amount, deposit.updated);
      if (successPost) updateMetaEvt();
    }
  }, [dData, updateMetaEvt]);

  //use for meta event, set the refreshtoken to state, as meta will use the token at first,previously have issue to get the refreshtoken
  // useEffect(() => {
  //   if (isLoggedIn && typeof refreshToken !== 'undefined') {
  //     setParams(refreshToken);
  //     // window.addEventListener('focus', () => setFocus(true), false);
  //     // window.addEventListener('blur', () => setFocus(false), false);
  //   }
  // }, [isLoggedIn, refreshToken]);

  useInterval(() => {
    if (isLoggedIn && users) {
      refetch();
      //use for meta event
      // const { isMetaEventSent, refreshToken } = users;
      // if (isMetaEventSent === 0) {
      //   setParams(refreshToken);
      // }
    }
  }, 60000);

  useEffect(() => {
    if (loading) return;
    if (data && data.active) ping();
  }, [loading, data, ping]);

  useEffect(() => {
    if (accessToken) startConnection(accessToken);
    return () => stopConnection();
  }, [accessToken, uid]);

  return (
    <>
      {/* {isLoggedIn && <IdleLogoutTimer warningMin={1} timeoutMin={15} />} */}
      {children}
    </>
  );
}
