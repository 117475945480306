import React , { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ROUTES } from 'common/constants/routing';
import { MobileImages } from 'common/constants/image-resource';
import GoBack from 'component/mobile/common/goback/GoBack';
import styles from './TopbarSecondary.module.css';
import { FormattedMessage } from 'react-intl';
import { useGetReferralSpinDetails } from 'services/query/referralSpinQuery';
import IconClose2 from 'component/mobile/common/icon/IconClose2';
import IconSideMenu from 'component/mobile/common/icon/IconSideMenu';
import { useTheme } from '@mui/material/styles';
import CustomIcon from 'component/mobile/common/icon/CustomIcon';
import ThemeIcon from 'component/mobile/common/icon/ThemeIcon';
import { setShowFirstSpinBonusModalOpen } from 'common/reducer-actions/popupActions';
import { useDispatch, useStore } from 'store/StateProvider';

const TopbarSecondary = ({
  isWelcomeSnackBar,
  isAffiliateSnackBar,
  noPrimaryLogo = false,
  logoLeft,
  openDrawer,
  toggleDrawer,
  showCloseButton,
  event,
  isLoggedIn,
}) => {
  //Utils
  const [store] = useStore();
  const { state } = useLocation();
  const prevPath = state?.prevPath || null;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  //Axios hook
  const [,fetchPageDetail] = useGetReferralSpinDetails();
  //Store
  const referralSpinDetails = store.referral?.referralSpinDetails;

  
  const closeHandler = () => {
    if (prevPath === ROUTES.USER.LUCKY_WHEEL.ROOT) {
      navigate(ROUTES.HOME);
    } else {
      navigate(-1);
    }
  };

  const backEventHandler = () => {
    setShowFirstSpinBonusModalOpen(dispatch, false);
    navigate(-1);
  };

  useEffect(() => {
    if (noPrimaryLogo) fetchPageDetail();
  }, [fetchPageDetail, noPrimaryLogo]);

  return (
    <>
      <div
        className={`${noPrimaryLogo ? styles.headerWithTitle : styles.header} ${
          !logoLeft ? styles.justifyCenter : ''
        } ${
          (isWelcomeSnackBar && !isLoggedIn && showCloseButton && !event) ||
          (isAffiliateSnackBar && isLoggedIn && showCloseButton && !event)
            ? styles.hasTop
            : styles.noneTop
        }`}
        id="topbar-header"
      >
        {!logoLeft && <GoBack arrowClass={styles.backArrow} backEvent={backEventHandler} />}
        {logoLeft && (
          <div className={styles.leftHeader}>
              <div onClick={toggleDrawer(!openDrawer)} className={styles.navLink}>
              <IconSideMenu
                isActive={openDrawer}
                color={theme.palette.text.txtSecondary}
                width={28}
                height={28}
              />
            </div>
            <Link to={ROUTES.HOME}>
              <CustomIcon
                src={MobileImages.logoPrimary}
                width={126}
                height={18.67}
                alt="logo"
                className={styles.logo}
                // className={`${styles.logo} ${
                //   (isWelcomeSnackBar && !isLoggedIn && showCloseButton) ||
                //   (isAffiliateSnackBar && isLoggedIn && showCloseButton)
                //     ? styles.marginHasTop
                //     : ''
                // }`}
              />
            </Link>
          </div>
        )}
        {!logoLeft && (
          <div>
            {noPrimaryLogo ? (
              <div className={styles.title}>
                <FormattedMessage
                  id="referral_promotion.ads_title"
                  values={{
                    rewardAmount: <span>{referralSpinDetails?.targetAmount}</span>,
                  }}
                />
              </div>
            ) : (
              <Link to={ROUTES.HOME}>
                <CustomIcon
                  src={MobileImages.logoPrimary}
                  width={126}
                  height={18.67}
                  alt="logo"
                  className={styles.logo}
                />
              </Link>
            )}
          </div>
        )}
        {showCloseButton && (
          <div className={styles.closeContainer} onClick={closeHandler}>
            <ThemeIcon IconComponent={IconClose2} level="active" />
          </div>
        )}
      </div>
    </>
  );
};

export default TopbarSecondary;
