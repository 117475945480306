import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, useLocation } from 'react-router-dom';
import Topbar from './Topbar/Topbar';
import TopbarSecondary from './Topbar/TopbarSecondary';
import Footer from './Footer/Footer';
import styles from './Layout.module.css';
import { useNavigate } from 'react-router-dom';

import { getLocalStorage, isUserLoggedIn } from 'common/helpers/localStorage';
import WelcomeBonus from './Topbar/snackbar/WelcomeBonus';
import AffiliateInvite from './Topbar/snackbar/AffiliateInvite';
import { useDispatch, useStore } from 'store/StateProvider';
import {
  setAffiliateSnackBar,
  setWelcomeSnackBar,
  setSideBarDrawerOpen,
} from 'common/reducer-actions/commonActions';
import { ROUTES } from 'common/constants/routing';
import Sidebar from './Topbar/sidebar/Sidebar';

const Layout = ({
  isPrimary = false,
  showCloseButton,
  handleCloseGame = null,
  dynamicScreen,
  hasLeftBack = false,
  event = false,
  noPrimaryLogo,
  logoLeft = false,
  snackBar = true,
  isSports = false,
  fullBg = false,
}) => {
  //Utils
  const intl = useIntl();
  const [store] = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = isUserLoggedIn();
  const dispatch = useDispatch();
  //Store
  const isWelcomeSnackBar = store.common?.isWelcomeSnackBar;
  const isAffiliateSnackBar = store.common?.isAffiliateSnackBar;
  const sideBarDrawerOpen = store.common?.sideBarDrawerOpen;

  const toggleDrawer = (open) => (event) => {
    setSideBarDrawerOpen(dispatch, open);
  };

  //clsoe the drawer if navigate to other pages
  useEffect(() => {
    setSideBarDrawerOpen(dispatch, false);
  }, [dispatch, navigate]);

  useEffect(() => {
    if (!isLoggedIn) {
      const welcomeSnack = getLocalStorage('welcomeSnackbar');
      setWelcomeSnackBar(dispatch, welcomeSnack);
    } else {
      const affiliateSnackbar = getLocalStorage('affiliateSnackbar');
      setAffiliateSnackBar(dispatch, affiliateSnackbar);
    }
  }, []);

  // const handleCloseSnackbar = useCallback(
  //   (prop, value) => {
  //     setIsSnackbar(value);
  //     if (prop === 'affiliateInvite') {
  //       setLocalStorage('affiliateSnackbar', false);
  //       setAffiliateSnackBar(dispatch, false);
  //     } else if (prop === 'welcomeBonusSnackbar') {
  //       setLocalStorage('welcomeSnackbar', false);
  //       setWelcomeSnackBar(dispatch, false);
  //     }
  //     // window.scrollTo(0, 0);
  //   },
  //   [dispatch],
  // );

  return (
    <div
      lang={intl.locale}
      className={`${styles.container} ${fullBg ? styles.customBg : ''}`}
    >
      <div
        className={`${styles.contentContainer} ${
          location.pathname === ROUTES.BONUS.ROOT ? styles.bonusContentNonImgBg : ''
        }`}
        id="layoutContent"
      >
        {!handleCloseGame && !event && !fullBg && <WelcomeBonus />}
        {!handleCloseGame && !event && !fullBg && <AffiliateInvite />}
        {!fullBg &&
          (isPrimary ? (
            <Topbar
              isWelcomeSnackBar={isWelcomeSnackBar}
              isAffiliateSnackBar={isAffiliateSnackBar}
              showCloseButton={showCloseButton}
              handleCloseGame={handleCloseGame}
              hasLeftBack={hasLeftBack}
              openDrawer={sideBarDrawerOpen}
              toggleDrawer={toggleDrawer}
            />
          ) : (
            <TopbarSecondary
              isWelcomeSnackBar={isWelcomeSnackBar}
              isAffiliateSnackBar={isAffiliateSnackBar}
              noPrimaryLogo={noPrimaryLogo}
              logoLeft={logoLeft}
              showCloseButton={showCloseButton}
              event={event}
              isLoggedIn={isLoggedIn}
              openDrawer={sideBarDrawerOpen}
              toggleDrawer={toggleDrawer}
            />
          ))}
        <div
          className={`${isSports ? styles.sportsWrapper : styles.wrapper} ${
            dynamicScreen ? styles.wrapper3 : ''
          }`}
          style={{
            paddingTop: `${
              isSports || !snackBar || fullBg
                ? '60px'
                : (isWelcomeSnackBar && !isLoggedIn) || (isAffiliateSnackBar && isLoggedIn)
                ? '105px'
                : '60px'
            }`,
          }}
        >
          <Sidebar
            openDrawer={sideBarDrawerOpen}
            toggleDrawer={toggleDrawer}
            snackBar={(isWelcomeSnackBar && !isLoggedIn) || (isAffiliateSnackBar && isLoggedIn)}
            backButtonLayout={snackBar}
            isSports={isSports}
          />
          <Outlet />
        </div>
        {!fullBg && <Footer />}
      </div>
    </div>
  );
};

export default Layout;
